import hash from 'hash-sum'
import { l } from '@manychat/manyui'

import { hideSubscriberModal } from 'common/audience/actions/audienceActions'
import * as atypes from 'common/audience/constants/AudienceReduxActionTypes'
import { getCurrentUser, getSelection } from 'common/audience/selectors/audienceSelectors'
import { UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import { alert } from 'common/core'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import {
  getCanSubscribersBulkDeletion,
  getCurrentAccountID,
} from 'common/core/selectors/appSelectors'
import { BusinessError } from 'shared/api/lib/errors/business'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { AudienceApi } from 'shared/api/requests/audience'
import { audienceApiGuard } from 'shared/api/requests/audience/guards'
import {
  AudienceDeleteDataRequest,
  AudienceExportDataRequest,
} from 'shared/api/requests/audience/schemas'
import { getPathname, navigatePollyfill } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

interface Tag {
  tag_id: number
  tag_name: string
}

interface Sequence {
  sequence_id: number
  sequence: {
    sequence_id: number
    name: string
  }
}

export const handleAudienceErrors = (
  businessErrors: BusinessError[],
  endpoint: string | undefined,
) => {
  const showError = (error: BusinessError) => alert(error.message, 'danger')

  const unhandledErrors = [
    audienceApiGuard.search.isFilterItemValueNotSetError,
    audienceApiGuard.search.isWrongTimezoneValueError,
    audienceApiGuard.search.isTagNotFoundError,
    audienceApiGuard.search.isUserIdNotFoundError,
    audienceApiGuard.search.isProAccountRequiredError,
    audienceApiGuard.search.isWidgetNotFoundError,
    audienceApiGuard.search.isSegmentNotFoundError,
    audienceApiGuard.search.isSequenceNotFoundError,
  ].reduce((errors, guard) => applyHandlerByGuard(errors, guard, showError), businessErrors)

  reportUnhandledErrors(unhandledErrors, endpoint)

  return unhandledErrors
}

export const fetchSubscribers = (): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    const { searchQuery, filter } = getState().audience

    const body: { q: string; filter?: typeof filter } = { q: searchQuery }
    if (filter) {
      body.filter = filter
    }

    const fetchArgs = { urlParams: {}, body }
    const fetchArgsHash = hash(fetchArgs)
    const dropItems = fetchArgsHash !== getState().audience.fetchArgsHash || false

    dispatch({
      type: atypes.AUDIENCE_FETCH_REQUEST,
      fetchArgs: { urlParams: {}, body },
      fetchArgsHash: hash(body),
      dropItems,
    })
    try {
      const response = await AudienceApi.search({ body })

      const items = response.data.users.map((item) => ({
        ...item,
        id: item.user_id,
      }))
      dispatch({
        type: atypes.AUDIENCE_FETCH_RESPONSE,
        data: {
          ...response.data,
          items,
          users: items,
        },
        dropItems,
        fetchArgs: { body },
        fetchArgsHash: hash(body),
      })
    } catch (error) {
      handleCatch(error, (responseError) => {
        handleAudienceErrors(responseError.$errors, responseError.endpoint)
      })
      dispatch({
        type: atypes.AUDIENCE_FETCH_ERROR,
        data: { error: { state: false } },
      })
    }
  }
}

export const fetchSubscriber = (subscriberId: string): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_CURRENT_FETCH_REQUEST',
      id: subscriberId,
    })

    try {
      const { data } = await AudienceApi.details({ query: { user_id: subscriberId } })
      dispatch({
        type: 'AUDIENCE_CURRENT_FETCH_RESPONSE',
        data: {
          ...data,
          item: { ...data.user, id: data.user.user_id },
          id: data.user.user_id,
        },
      })
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          audienceApiGuard.details.isSubscriberNotFoundError,
          (error) => alert(error.message, 'danger'),
        )
        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })

      dispatch({
        type: 'AUDIENCE_CURRENT_FETCH_ERROR',
        data: { error: { state: false, id: subscriberId } },
      })
    }
  }
}

export const addSubscriberTag =
  (id: string, tag: Tag): IAsyncThunkAction =>
  async (dispatch) => {
    if (!tag.tag_id) return

    dispatch({
      type: 'AUDIENCE_ADDTAG_REQUEST',
      id: id,
      tag: tag,
    })

    try {
      await AudienceApi.addTag({ query: { user_id: id, tag_id: tag.tag_id } })
      dispatch({
        type: 'AUDIENCE_ADDTAG_RESPONSE',
        id: id,
        tag: tag,
      })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_ADDTAG_FETCH_ERROR',
        data: { error: { state: false, id: tag.tag_id } },
      })
    }
  }

export const deleteSubscriberTag = (id: string, tagId: number): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_DELETETAG_REQUEST',
      id: id,
      tagId: tagId,
    })

    try {
      await AudienceApi.removeTag({ query: { user_id: id, tag_id: tagId } })

      dispatch({
        type: 'AUDIENCE_DELETETAG_RESPONSE',
        id: id,
        tagId: tagId,
      })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_DELETETAG_FETCH_ERROR',
        data: { error: { state: false, id: tagId } },
      })
    }
  }
}

export const addSubscriberSequence =
  (id: string, sequence: Sequence): IAsyncThunkAction =>
  async (dispatch) => {
    analyticsService.sendEvent('APP.LIVE_CHAT.ADD_SEQUENCE')
    dispatch({
      type: 'AUDIENCE_ADDSEQUENCE_REQUEST',
      id: id,
      sequence,
    })

    try {
      await AudienceApi.addToSequence({ query: { user_id: id, sequence_id: sequence.sequence_id } })

      dispatch({
        type: 'AUDIENCE_ADDSEQUENCE_RESPONSE',
        id: id,
        sequence,
      })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_ADDSEQUENCE_FETCH_ERROR',
        data: { error: { state: false, id: sequence.sequence_id } },
      })
    }
  }

export const deleteSubscriberSequence =
  (id: string, sequenceId: number): IAsyncThunkAction =>
  async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_DELETESEQUENCE_REQUEST',
      id: id,
      sequenceId: sequenceId,
    })
    try {
      await AudienceApi.removeFromSequece({
        query: { user_id: id, sequence_id: sequenceId },
      })

      dispatch({
        type: 'AUDIENCE_DELETESEQUENCE_RESPONSE',
        id: id,
        sequenceId: sequenceId,
      })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_DELETESEQUENCE_FETCH_ERROR',
        data: { error: { state: false, id: sequenceId } },
      })
    }
  }

export const unsubscribe =
  (userID: string): IAsyncThunkAction =>
  async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_UNSUBSCRIBE_REQUEST',
      id: userID,
    })
    try {
      await AudienceApi.unsubscribe({ query: { user_id: userID } })
      dispatch({ type: atypes.AUDIENCE_UNSUBSCRIBE_RESPONSE, id: userID })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_UNSUBSCRIBE_FETCH_ERROR',
        data: { error: { state: false, id: userID } },
      })
    }
  }

export const unsubscribeFromEmail =
  (userID: string): IAsyncThunkAction =>
  async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_UNSUBSCRIBEFROMEMAIL_REQUEST',
      id: userID,
    })
    try {
      await AudienceApi.unsubscribeFromEmail({ query: { user_id: userID } })
      dispatch({ type: atypes.AUDIENCE_UNSUBSCRIBEFROMEMAIL_RESPONSE, id: userID })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_UNSUBSCRIBEFROMEMAIL_FETCH_ERROR',
        data: { error: { state: false, id: userID } },
      })
    }
  }

export const unsubscribeFromSMS =
  (userID: string): IAsyncThunkAction =>
  async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_UNSUBSCRIBEFROMSMS_REQUEST',
      id: userID,
    })
    try {
      await AudienceApi.unsubscribeFromSMS({ query: { user_id: userID } })
      dispatch({ type: atypes.AUDIENCE_UNSUBSCRIBEFROMSMS_RESPONSE, id: userID })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_UNSUBSCRIBEFROMSMS_FETCH_ERROR',
        data: { error: { state: false, id: userID } },
      })
    }
  }

export const unsubscribeFromTelegram =
  (userID: string): IAsyncThunkAction =>
  async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_UNSUBSCRIBEFROMTELEGRAM_REQUEST',
      id: userID,
    })
    try {
      await AudienceApi.unsubscribeFromTelegram({ query: { user_id: userID } })
      dispatch({ type: 'AUDIENCE_UNSUBSCRIBEFROMTELEGRAM_RESPONSE', id: userID })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_UNSUBSCRIBEFROMTELEGRAM_FETCH_ERROR',
        data: { error: { state: false, id: userID } },
      })
    }
  }

export const unsubscribeFromInstagram =
  (userID: string): IAsyncThunkAction =>
  async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_UNSUBSCRIBEFROMINSTAGRAM_REQUEST',
      id: userID,
    })
    try {
      await AudienceApi.unsubscribeFromInstagram({ query: { user_id: userID } })
      dispatch({ type: atypes.AUDIENCE_UNSUBSCRIBEFROMINSTAGRAM_RESPONSE, id: userID })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_UNSUBSCRIBEFROMINSTAGRAM_FETCH_ERROR',
        data: { error: { state: false, id: userID } },
      })
    }
  }

export const unsubscribeFromWhatsApp =
  (userID: string): IAsyncThunkAction =>
  async (dispatch) => {
    dispatch({
      type: 'AUDIENCE_UNSUBSCRIBEFROMWHATSAPP_REQUEST',
      id: userID,
    })
    try {
      await AudienceApi.unsubscribeFromWhatsApp({ query: { user_id: userID } })
      dispatch({ type: atypes.AUDIENCE_UNSUBSCRIBEFROMWHATSAPP_RESPONSE, id: userID })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: 'AUDIENCE_UNSUBSCRIBEFROMWHATSAPP_FETCH_ERROR',
        data: { error: { state: false, id: userID } },
      })
    }
  }

export const deleteData =
  (payload: AudienceDeleteDataRequest): IAsyncThunkAction =>
  async (dispatch, getState) => {
    const { subscriber_id } = payload

    const state = getState()
    const isSubscribersNewDeletionEnabled = getCanSubscribersBulkDeletion(state)
    dispatch({
      type: atypes.AUDIENCE_DELETE_SUBSCRIBER_DATA_REQUEST,
      subscriber_id: subscriber_id,
    })

    try {
      const { data } = await AudienceApi.deleteData({ body: { subscriber_id } })
      dispatch({
        type: atypes.AUDIENCE_DELETE_SUBSCRIBER_DATA_RESPONSE,
        data: {
          subscriber: data.subscriber,
          isSubscribersNewDeletionEnabled,
        },
      })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: atypes.AUDIENCE_DELETE_SUBSCRIBER_DATA_ERROR,
        data: { error: { state: false, id: subscriber_id } },
      })
    }
  }

export const exportData =
  (payload: AudienceExportDataRequest): IAsyncThunkAction =>
  async (dispatch) => {
    const { subscriber_id } = payload
    dispatch({
      type: atypes.AUDIENCE_EXPORT_SUBSCRIBER_DATA_REQUEST,
      subscriber_id: subscriber_id,
    })
    try {
      const { data } = await AudienceApi.exportData({ body: { subscriber_id } })
      dispatch({
        type: atypes.AUDIENCE_EXPORT_SUBSCRIBER_DATA_RESPONSE,
        data,
      })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: atypes.AUDIENCE_EXPORT_SUBSCRIBER_DATA_ERROR,
        data: { error: { state: false, id: subscriber_id } },
      })
    }
  }

export const exportCustomAudience = (uuid: string): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    try {
      await AudienceApi.prepareCsvExport({
        body: {
          ...getSelection(getState()),
          async: uuid,
        },
      })
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandled = applyHandlerByGuard(
          responseError.$errors,
          audienceApiGuard.prepareCsvExport.isProError,
          (error) => {
            dispatch(
              handleGoProError(error, {
                products: error.error_data.products,
                source: UpgradeSource.AUDIENCE_EXPORT,
                modalTitle: l.translate('Unlock the ability to export PSIDs with Manychat Pro'),
                message: l.translate(
                  "You aren't able to export PSIDs on the current plan. Upgrade for unlimited access.",
                ),
              }),
            )
          },
        )

        reportUnhandledErrors(unhandled, responseError.endpoint)
        throw error
      })
    }
  }
}

export const checkZapierHook = (actionType: string, key: string, value: number) => async () => {
  try {
    const result = await AudienceApi.checkZapierHook({
      query: {
        type: actionType,
        tag_id: key === 'tag_id' ? value : undefined,
        field_id: key === 'field_id' ? value : undefined,
      },
    })

    return result.data
  } catch (error) {
    handleCatch(error)
    return { has_hook: false }
  }
}

export const closeMigrateCUFToSFModal = () => ({
  type: atypes.AUDIENCE_CLOSE_MIGRATE_CUF_TO_SF_MODAL,
})

anotherTabNotificationsListener.on('notify_subscribers_deleted', (data, dispatch, getState) => {
  const state = getState()
  const pathname = getPathname()
  const accountId = getCurrentAccountID(state as RootState)
  const isSubscribersNewDeletionEnabled = getCanSubscribersBulkDeletion(state as RootState)

  if (!isSubscribersNewDeletionEnabled) return

  const deletedSubscribersIds = data.model?.subscribers || []
  const currentUserId = getCurrentUser(state as RootState)?.user_id
  const isCurrentUserOpen = deletedSubscribersIds.includes(currentUserId)

  if (isCurrentUserOpen) {
    dispatch(hideSubscriberModal())
    // If a user is in Live Chat or somewhere else out of the Audience page and deletes a contact, we don't need to redirect them to the Audience page
    if (pathname !== `/${accountId}/subscribers/${currentUserId}`) return
    navigatePollyfill(linkURL('/subscribers'))
  }

  dispatch({
    type: atypes.AUDIENCE_BULK_DELETE_SUBSCRIBER_DATA,
    data: {
      subscribers: deletedSubscribersIds,
      isCurrentUserOpen,
    },
  })
})

import { getHandle } from 'utils/router/utils/getHandle'

export const RegistrationRoutes = [
  {
    path: 'registration/:section/:channel?/:page_id?/:type?',
    alias: 'registration',
    handle: getHandle('registration'),
    lazy: () => import('./Registration.page'),
  },
] as const

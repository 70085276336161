import { ICurrentAccount } from 'common/core/interfaces/currentAccount'
import { ISidebarAccount } from 'common/core/interfaces/sideBarAccount'
import AccountModel from 'common/core/models/Account'
import { IExtendedCurrentAccount } from 'common/core/selectors/appSelectors'
import { navigatePollyfill } from 'utils/router/tools'

export const redirectToReturnParam = ({
  page,
  returnSuccessParam,
  returnParam,
}: {
  page: IExtendedCurrentAccount | ISidebarAccount | null
  returnSuccessParam: string | null
  returnParam: string
}) => {
  if (page && AccountModel.isProOrTrial(page as ICurrentAccount) && returnSuccessParam) {
    return navigatePollyfill(returnSuccessParam)
  }

  return navigatePollyfill(returnParam)
}

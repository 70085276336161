import React, { useMemo, useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'

import { FormTextField } from '../FormField/FormTextField'

import { DecrementalIcon } from './icons/DecrementalIcon'
import { IncrementalIcon } from './icons/IncrementalIcon'
import { NumberInputV2Props } from './interfaces'
import formatInput from './utils/formatInput'

import cm from './NumberInputV2.module.css'

/**
 * This controlled component provides a number input field with increment and decrement buttons.
 *
 * [Figma](https://www.figma.com/file/Xf38PdCKeISWnBoh0pLFIIWf/🧱-MC-Desktop-Components?type=design&node-id=19293-58399&mode=dev)
 */
export const NumberInputV2 = ({
  id: outerId,
  inputRef,
  disabled = false,
  invalid = false,
  showArrows = true,
  variant,
  errorText,
  autoFocus,
  value,
  onChange,
  max,
  min,
  step = 1,
  inputMode = 'numeric',
  className,
  labelId,
  maxSymbols,
  helpText,
  infoText,
  infoTextWidth,
  lozenge,
  onKeyDown,
  allowEmpty,
  onBlur,
  onFocus,
  ...props
}: NumberInputV2Props) => {
  const [internalValue, setInternalValue] = useState(String(value))
  const id = useMemo(() => outerId || `${uuid()}-checkbox`, [outerId])

  useEffect(() => {
    setInternalValue(String(value))
  }, [value])

  const isIncrementDisabled =
    disabled ||
    (value !== undefined && (value === max || (max !== undefined && Number(value) + step > max)))

  const isDecrementDisabled =
    disabled ||
    (value !== undefined && (value === min || (min !== undefined && Number(value) - step < min)))

  const handleIncrement = useCallback(() => {
    if (value !== undefined) {
      const newValue = Number(value) + step
      if (max === undefined || newValue <= max) {
        onChange(newValue)
      }
    }
  }, [value, max, onChange, step])

  const handleDecrement = useCallback(() => {
    if (value !== undefined) {
      const newValue = Number(value) - step
      if (min === undefined || newValue >= min) {
        onChange(newValue)
      }
    }
  }, [value, min, onChange, step])

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(event)
      }
    },
    [onFocus],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const unformattedValue = event.target.value
      const formattedValue = formatInput(unformattedValue, maxSymbols, inputMode)
      setInternalValue(formattedValue)

      const num = Number(formattedValue)
      if (!isNaN(num)) {
        if ((max !== undefined && num > max) || (min !== undefined && num < min)) {
          return
        }
        onChange(num)
      }
    },
    [inputMode, maxSymbols, max, min, onChange],
  )

  /** handleBlur  ensures that when the user leaves the field, the value is always valid.
   */

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const formattedValue = formatInput(internalValue, maxSymbols, inputMode)
      let num = Number(formattedValue)
      if (isNaN(num)) {
        num = min !== undefined ? min : 0
      }
      if (max !== undefined && num > max) {
        num = max
      }
      if (min !== undefined && num < min) {
        num = min
      }
      if (num !== Number(value)) {
        onChange(num)
      }
      setInternalValue(String(num))
      if (onBlur) {
        onBlur(event)
      }
    },
    [value, onBlur, internalValue, maxSymbols, inputMode, max, min, onChange],
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (disabled) return

      if (event.key === 'ArrowUp') {
        event.preventDefault()
        handleIncrement()
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        handleDecrement()
      }

      if (onKeyDown) {
        onKeyDown(event)
      }
    },
    [disabled, handleIncrement, handleDecrement, onKeyDown],
  )

  const inputClasses = cx(cm.numberInputV2, { [cm.numberInputV2_withArrows]: showArrows })
  /* While testing in Safari, it was found that the component allowed users to type letters even
   * though the input type was set to 'number'. To resolve this issue, the input type was changed
   * to 'text' and additional validation was added to ensure only numeric input is accepted.
   *
   * For more information on why this change was necessary, see the related discussion here:
   * https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
   */

  return (
    <FormTextField
      id={id}
      pattern="[0-9]*"
      labelId={labelId}
      aria-disabled={disabled}
      type="text"
      ref={inputRef}
      value={internalValue}
      variant={variant}
      helpText={helpText}
      maxLength={maxSymbols}
      errorText={errorText}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
      disabled={disabled}
      step={step}
      invalid={invalid}
      autoFocus={autoFocus}
      max={max}
      min={min}
      lozenge={lozenge}
      infoText={infoText}
      infoTextWidth={infoTextWidth}
      inputMode={inputMode}
      className={className}
      fieldClasses={{ input: inputClasses, label: 'text-sm' }}
      autoComplete="off"
      {...props}
    >
      {showArrows && (
        <div className={cm['buttons-container']}>
          <button
            className={cx(cm.button, cm.increment)}
            aria-label={props.upAriaLabel}
            disabled={isIncrementDisabled}
            onClick={handleIncrement}
          >
            <IncrementalIcon className={cm.icon} />
          </button>
          <button
            className={cx(cm.button, cm.decrement)}
            aria-label={props.downAriaLabel}
            disabled={isDecrementDisabled}
            onClick={handleDecrement}
          >
            <DecrementalIcon className={cm.icon} />
          </button>
        </div>
      )}
    </FormTextField>
  )
}

import { createSelector } from 'reselect'

import { getCoexistenceContactImportDeadline } from 'apps/whatsApp/helpers/getCoexistenceContactImportDeadline'
import { ButtonType } from 'common/builder/constants/ButtonType'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { ChannelType } from 'common/core/constants/ChannelType'
import { ChannelStatus } from 'common/core/interfaces/channelStatuses'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { isWhatsAppChannelConnected } from 'common/core/selectors/channelSelectors'
import { WaCoexistenceImportContactsState } from 'shared/api/requests/whatsApp/schemas'
import { formatToShortenedLink } from 'utils/misc'

export const getWhatsAppLinkCaption = (state: RootState, builderId: string, buttonId: string) => {
  const button = entitySelectors.getButtonById(state, builderId, buttonId)

  if (!button) return ''

  const shortener = 'mc.ht'

  if (button.type === ButtonType.URL) {
    return formatToShortenedLink(shortener)
  }

  const ref = 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-_-_XXXXXXXXXXXXXXXX'
  const instagramChannel = getCurrentAccount(state)?.instagram_channel
  if (
    button.type === ButtonType.CROSS_CHANNEL_CONTENT &&
    button?.channel === ChannelType.INSTAGRAM
  ) {
    return `https://ig.me/m/${instagramChannel?.username}?ref=${ref}`
  }

  const currentAccountLink = getCurrentAccount(state)?.fb_channel?.link
  return `${currentAccountLink}?ref=${ref}`
}

export const getWhatsAppChannel = (state: RootState) => {
  const currentAccount = getCurrentAccount(state)
  return currentAccount.whatsapp_channel
}

export const getWhatsAppPhoneNumberWithCountryCode = createSelector(
  getWhatsAppChannel,
  (whatsAppChannel) => {
    if (!isWhatsAppChannelConnected(whatsAppChannel)) return null
    if (!whatsAppChannel.country_code || !whatsAppChannel.phone_number) return null

    return `${whatsAppChannel.country_code}${whatsAppChannel.phone_number}`
  },
)

export const hasWhatsAppProErrorStatus = (state: RootState) => {
  const currentAccount = getCurrentAccount(state)
  if (!isWhatsAppChannelConnected(currentAccount.whatsapp_channel)) return false
  return currentAccount.whatsapp_channel.status === ChannelStatus.PRO_ERROR
}

export const isWhatsAppConnected = (state: RootState) => {
  const channel = getWhatsAppChannel(state)

  if (!isWhatsAppChannelConnected(channel)) return false
  return Boolean(channel.is_channel_enabled)
}

export const getWhatsAppChannelConnectedTimestamp = (state: RootState) => {
  const channel = getWhatsAppChannel(state)
  if (!isWhatsAppChannelConnected(channel)) return null
  return channel.ts_channel_connected || null
}

export const isWhatsAppCoexistenceUsed = (state: RootState) => {
  const channel = getWhatsAppChannel(state)
  if (!isWhatsAppChannelConnected(channel)) return false
  return channel?.is_coexistence_used || false
}

export const getWhatsAppCoexistenceContactImportPeriod = (state: RootState) => {
  const channel = getWhatsAppChannel(state)
  if (!isWhatsAppChannelConnected(channel)) return null
  return channel?.coexistence_import_contacts_period || null
}

export const getWhatsAppCoexistenceContactImportState = (state: RootState) => {
  const channel = getWhatsAppChannel(state)
  if (!isWhatsAppChannelConnected(channel)) return null
  return channel?.coexistence_import_contacts_state || null
}

export const getWhatsAppCoexistenceContactImportDeadline = (state: RootState) => {
  const waChannelCreatedAt = getWhatsAppChannelConnectedTimestamp(state)
  if (!waChannelCreatedAt) return { hoursLeft: 0, deadlineWithTimezone: null }

  const { hoursLeft, deadlineWithTimezone } =
    getCoexistenceContactImportDeadline(waChannelCreatedAt)

  return { hoursLeft, deadlineWithTimezone }
}

export const isCoexistenceContactImportAvailable = (state: RootState) => {
  const isWaCoexistenceUsed = isWhatsAppCoexistenceUsed(state)
  const { hoursLeft } = getWhatsAppCoexistenceContactImportDeadline(state)
  // Contacts can be imported if the WA coexistence is used and the WA channel was created less or equal than 24 hours ago
  return isWaCoexistenceUsed && hoursLeft > 0 && hoursLeft <= 24
}

export const shouldWhatsAppHomeBannersBeHidden = (state: RootState) => {
  const isContactImportAvailable = isCoexistenceContactImportAvailable(state)
  const importState = getWhatsAppCoexistenceContactImportState(state)
  // If the contact import is available, but the import has not been started yet or is in progress, we should hide the WA banners on the Home page until the import is completed
  return (
    isContactImportAvailable &&
    (!importState || importState === WaCoexistenceImportContactsState.PENDING)
  )
}

export const isWaCoexistenceContactImportCommunicationAvailable = (state: RootState) => {
  const isContactImportAvailable = isCoexistenceContactImportAvailable(state)
  const importContactsPeriod = getWhatsAppCoexistenceContactImportPeriod(state)
  // If the contact import is available and the user has not imported contacts yet, we should show static notification and modal window
  return isContactImportAvailable && !importContactsPeriod
}

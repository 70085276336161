import shuffle from 'lodash/shuffle'

import { Question } from 'shared/api/requests/questionnaire/schemas'

export const getIsInvitationCase = (): boolean => {
  const searchParams = new URLSearchParams(window.location.search)
  return Boolean(searchParams.get('return')?.includes('invitation'))
}

const OTHER_IDS_PATTERN = '__other'

// TODO: remove after final migration to the new q-re
// Helper function to shuffle visible items in a question by question ID
// It shuffles visible items and keeps hidden items in the same order
// Other option is always the last item in the list
// is_extension is a flag that indicates if the item is hidden
export const shuffleVisibleQuestionItemsById = (
  questions: Question[],
  questionIDs: string[],
): Question[] => {
  return questions.map((question) => {
    if (!questionIDs.includes(question.id)) {
      return question
    }

    const { items } = question
    const otherOption = items.find(
      (item) => !item.is_extension && item.id.includes(OTHER_IDS_PATTERN),
    )
    const hiddenItems = items.filter((item) => item.is_extension)
    const visibleItemsWithoutOther = items.filter(
      (item) => !item.is_extension && !item.id.includes(OTHER_IDS_PATTERN),
    )

    const shuffledVisibleItems = shuffle(visibleItemsWithoutOther)

    const allItems = [
      ...shuffledVisibleItems,
      ...hiddenItems,
      ...(otherOption ? [otherOption] : []),
    ]

    return {
      ...question,
      items: allItems,
    }
  })
}

import get from 'lodash/get'

import { questionAdapter } from 'apps/questionnaire/models/Questionnaire/adapter'
import { QuestionnaireType } from 'apps/questionnaire/models/Questionnaire/constants'

import {
  QUESTIONNAIRE_SHOW_STEP,
  QUESTIONNAIRE_FETCH_QUESTIONS_RESPONSE,
  QUESTIONNAIRE_UPDATE_QUESTIONS,
  QUESTIONNAIRE_GET_ANSWERS_RESPONSE,
} from '../constants/questionnaireReduxActionTypes'

const initialState = {
  [QuestionnaireType.PAGE]: {},
  [QuestionnaireType.USER]: {},
}

export default function questionnaireReducer(state = initialState, action) {
  switch (action.type) {
    case QUESTIONNAIRE_FETCH_QUESTIONS_RESPONSE:
      return {
        ...state,
        [action.questionnaireType]: {
          ...state[action.questionnaireType],
          questions: get(action, 'data.questions', []).map(questionAdapter),
          lastStep: 0,
          isShowWizardHeader: action.data?.is_show_wizard_header ?? true,
          shop: action.data?.shop || null,
        },
      }

    case QUESTIONNAIRE_UPDATE_QUESTIONS:
      return {
        ...state,
        [action.questionnaireType]: {
          ...state[action.questionnaireType],
          questions: get(state, `${action.questionnaireType}.questions`, []).map(
            (sourceQuestion) =>
              action.questions.find((targetQuestion) => targetQuestion.id === sourceQuestion.id) ||
              sourceQuestion,
          ),
        },
      }

    case QUESTIONNAIRE_SHOW_STEP:
      return {
        ...state,
        [action.questionnaireType]: {
          ...state[action.questionnaireType],
          lastStep: action.step - 1,
        },
      }

    case QUESTIONNAIRE_GET_ANSWERS_RESPONSE: {
      return {
        ...state,
        [action.questionnaireType]: {
          ...state[action.questionnaireType],
          answers: {
            ...state[action.questionnaireType].answers,
            ...action.data.answers,
          },
        },
      }
    }
    default:
      return state
  }
}
